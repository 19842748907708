import { defineStore } from 'pinia'
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/browser'
import { ToasterType } from '../interfaces'

export const useErrorHandlerStore = defineStore('error-handler-store', () => {
  const errorMessage = ref<string>()
  const errorTitle = ref<string>('')
  const errorDisplay = ref<boolean>(false)
  const errorType = ref<ToasterType>(ToasterType.danger)

  function setError(message:any, title:string, error?:Error, context?:any) {
    errorDisplay.value = true
    errorTitle.value = title
    errorMessage.value = message
    errorType.value = ToasterType.danger
    Sentry.captureException(error, context)
    closeError()
  }

  function setSuccess(message:any, title:string) {
    errorDisplay.value = true
    errorTitle.value = title
    errorMessage.value = message
    errorType.value = ToasterType.success
    closeError()
  }

  function closeError(immediate?: boolean) {
    if (immediate) {
      errorDisplay.value = false
    } else {
      setTimeout(() => {
        errorDisplay.value = false
      }, 5000)
    }
  }

  return {
    errorDisplay,
    errorMessage,
    errorTitle,
    errorType,
    setError,
    setSuccess,
    closeError
  }
})
